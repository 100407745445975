<template>
<div id="intro-wrap">
	<div class="top-banner-wrap">
		<div>
			<div class="main-title">세계를 선도하는</div>
			<div class="sub-title">글로벌인재</div>
            <div class="photo-watermark">Photo: Tom William Chapman, Runway Beauty</div>
        </div>
	</div>
	<div class="section-cont">
		<h1>교육목적</h1>
		<div class="desc">INTO원격평생교육원은 모든사람이 평생교육을 통하여 학습에 참여할 수 있도록 사회교육의 기회를 제공한다. </div>
		<h1>교육목적 및 목표</h1>
		<ul class="desc">
			<li> - 4차 산업사회의 평생학습 기반조성</li>
			<li> - 개인의 생애에 평생교육의 균형적 기회제공</li>
			<li> - 민간자격증 취득과 전문적 인적자원과 능력개발</li>
			<li> - 평생교육을 통한 개인의 가치과 확립과 학력보완</li>
		</ul>
		
	</div>
</div>
</template>

<script setup>
</script>
<style scoped>
@import "../../assets/scss/intro.scss";
</style>
