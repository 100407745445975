<template>
    <header>
        <a href="javascript:;" @click="goAnotherPage('main', {page:'',pageCate:''})" class="logo">INTO원격평생교육원</a>
        <nav class="pc-menu-wrap">
            <span>
                <a href="javascript:;" @click="goAnotherPage('directors', {page:'w1',pageCate:'w'})" :class="{'route-active':menuObj.pageCate == 'w1'}" >교육원소개</a>
                <ul>
                    <li><a href="javascript:;" @click="goAnotherPage('purpose', {page:'w1',pageCate:'w'})" :class="{'route-active':menuObj.page == 'w1'}">교육목적</a></li>
                    <li><a href="javascript:;" @click="goAnotherPage('greetings', {page:'w6',pageCate:'w'})" :class="{'route-active':menuObj.page == 'w6'}">원장 인사말</a></li>
                    <li><a href="javascript:;" @click="goAnotherPage('location', {page:'w2',pageCate:'w'})" :class="{'route-active':menuObj.page == 'w2'}">교육원위치</a></li>
                </ul>
            </span>
            <span>
                <a href="javascript:;" @click="goAnotherPage('curriculum1', {page:'h1',pageCate:'h'})" :class="{'route-active':menuObj.pageCate == 'h'}">교육과정소개</a>
                <ul>
                    <li><a href="javascript:;" @click="goAnotherPage('curriculum1', {page:'h1',pageCate:'h'})" :class="{'route-active':menuObj.page == 'h1'}">독서심리치료지도사</a></li>
                    <li><a href="javascript:;" @click="goAnotherPage('curriculum2', {page:'h2',pageCate:'h'})" :class="{'route-active':menuObj.page == 'h2'}">미술심리치료지도사</a></li>
                    <li><a href="javascript:;" @click="goAnotherPage('curriculum3', {page:'h3',pageCate:'h'})" :class="{'route-active':menuObj.page == 'h3'}">네일드릴 전문가 과정</a></li>
                    <li><a href="javascript:;" @click="goAnotherPage('curriculum4', {page:'h4',pageCate:'h'})" :class="{'route-active':menuObj.page == 'h4'}">네일케어 전문가 과정</a></li>
                </ul>
            </span>
            <span>
                <a href="javascript:;" @click="goAnotherPage('lecroom', {page:'p1',pageCate:'p'})" :class="{'route-active':menuObj.pageCate == 'p'}" >원격교육</a>
                <ul>
                    <li><a href="javascript:;" @click="goAnotherPage('lecroom', {page:'p1',pageCate:'p'})" :class="{'route-active':menuObj.page == 'p1'}">원격강의실</a></li>
                    <li><a href="javascript:;" @click="goAnotherPage('lecroom2', {page:'p2',pageCate:'p'})" :class="{'route-active':menuObj.page == 'p2'}">오리엔테이션</a></li>
                </ul>
            </span>
            <span>
                <a href="javascript:;" @click="goAnotherPage('notice', {page:'n1',pageCate:'n'})" :class="{'route-active':menuObj.pageCate == 'n'}">행정실</a>
                <ul>
                    <li><a href="javascript:;" @click="goAnotherPage('notice', {page:'n1',pageCate:'n'})" :class="{'route-active':menuObj.page == 'n1'}">공지사항</a></li>
                    <li><a href="javascript:;" @click="goAnotherPage('faq', {page:'n2',pageCate:'n'})" :class="{'route-active':menuObj.page == 'n2'}">FAQ</a></li>
                    <li><a href="javascript:;" @click="goAnotherPage('data', {page:'n3',pageCate:'n'})" :class="{'route-active':menuObj.page == 'n3'}">행정자료실</a></li>
                    <li><a href="javascript:;" @click="goAnotherPage('inquiry', {page:'n4',pageCate:'n'})" :class="{'route-active':menuObj.page == 'n4'}">1:1문의</a></li>
                </ul>
            </span>
        </nav>
        <nav class="mobile-menu-wrap">
            <div class="mobile-menu">
                <button class="burger burger2" @click="showMobileMenu" :class="isShowMenu ? 'on' : ''">
                    <span></span>
                </button>
            </div>
            <div class="sub-menu-wrap" :class="{active:isShowMenu}">
                <Transition name="display" mode="out-in" >
                    <div class="sub-menu-cont"  v-if="isShowMenu" >
                        <a href="javascript:;" @click="goAnotherPage('directors', {page:'w1',pageCate:'w'})" :class="{'route-active':menuObj.pageCate == 'w1'}" >교육원소개</a>
                        <ul>
                            <li><a href="javascript:;" @click="goAnotherPage('purpose', {page:'w1',pageCate:'w'})" :class="{'route-active':menuObj.page == 'w1'}">교육목적</a></li>
                            <li><a href="javascript:;" @click="goAnotherPage('greetings', {page:'w6',pageCate:'w'})" :class="{'route-active':menuObj.page == 'w6'}">원장 인사말</a></li>
                            <li><a href="javascript:;" @click="goAnotherPage('location', {page:'w2',pageCate:'w'})" :class="{'route-active':menuObj.page == 'w2'}">교육원위치</a></li>
                        </ul>
                        <a href="javascript:;" @click="goAnotherPage('curriculum1', {page:'h1',pageCate:'h'})" :class="{'route-active':menuObj.pageCate == 'h'}">교육과정소개</a>
                        <ul>
                            <li><a href="javascript:;" @click="goAnotherPage('curriculum1', {page:'h1',pageCate:'h'})" :class="{'route-active':menuObj.page == 'h1'}">독서심리치료지도사</a></li>
                            <li><a href="javascript:;" @click="goAnotherPage('curriculum2', {page:'h2',pageCate:'h'})" :class="{'route-active':menuObj.page == 'h2'}">미술심리치료지도사</a></li>
                            <li><a href="javascript:;" @click="goAnotherPage('curriculum3', {page:'h3',pageCate:'h'})" :class="{'route-active':menuObj.page == 'h3'}">네일드릴 전문가 과정</a></li>
                            <li><a href="javascript:;" @click="goAnotherPage('curriculum4', {page:'h4',pageCate:'h'})" :class="{'route-active':menuObj.page == 'h4'}">네일케어 전문가 과정</a></li>
                        </ul>
                        <a href="javascript:;" @click="goAnotherPage('lecroom', {page:'p1',pageCate:'p'})" :class="{'route-active':menuObj.pageCate == 'p'}" >원격교육</a>
                        <ul>
                            <li><a href="javascript:;" @click="goAnotherPage('lecroom', {page:'p1',pageCate:'p'})" :class="{'route-active':menuObj.page == 'p1'}">원격강의실</a></li>
                            <li><a href="javascript:;" @click="goAnotherPage('lecroom2', {page:'p2',pageCate:'p'})" :class="{'route-active':menuObj.page == 'p2'}">오리엔테이션</a></li>
                        </ul>
                        <a href="javascript:;" @click="goAnotherPage('notice', {page:'n1',pageCate:'n'})" :class="{'route-active':menuObj.pageCate == 'n'}">행정실</a>
                        <ul>
                            <li><a href="javascript:;" @click="goAnotherPage('notice', {page:'n1',pageCate:'n'})" :class="{'route-active':menuObj.page == 'n1'}">공지사항</a></li>
                            <li><a href="javascript:;" @click="goAnotherPage('faq', {page:'n2',pageCate:'n'})" :class="{'route-active':menuObj.page == 'n2'}">FAQ</a></li>
                            <li><a href="javascript:;" @click="goAnotherPage('data', {page:'n3',pageCate:'n'})" :class="{'route-active':menuObj.page == 'n3'}">행정자료실</a></li>
                            <li><a href="javascript:;" @click="goAnotherPage('inquiry', {page:'n4',pageCate:'n'})" :class="{'route-active':menuObj.page == 'n4'}">1:1문의</a></li>
                        </ul>

                    </div>
                </Transition>
            </div>
        </nav>
    </header>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRouter,useRoute  } from 'vue-router';

const router = useRouter();
const route = useRoute();

let menuObj = ref({page:'',pageCate:''});
watch(route,() =>{
    const dataObj = history.state; // 이렇게 받는다.
    if(dataObj.dataObj !== undefined && dataObj.dataObj.page !== undefined) menuObj.value = dataObj.dataObj;
    if(isShowMenu.value) isShowMenu.value = false;
});

function goAnotherPage(_name, _params){
    router.push({
        name: _name,
        state: {
            dataObj : _params,
        }
    });
}
let isShowMenu = ref(0);
function showMobileMenu(){
    isShowMenu.value = isShowMenu.value ? 0 : 1;
}
</script>