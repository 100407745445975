<template>
    <div id="footer-wrap" style="">
        <div class="first-sec">
            <div class="title">Contact us online</div>
            <div class="sub-title">Press enquiries</div>
            <div>master@intoedus.com</div>
        </div>
        <div class="second-sec">
            <div class="title">Contact us in person</div>
            <div class="sub-title">Phone : +82 010-7578-1167</div>
            <div>address : 경기 성남시 분당구 정자일로 1 B동 1212호</div>
        </div>
  
    </div>
</template>
<style scoped>
@import "../assets/scss/footer.scss";
</style>