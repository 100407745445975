<template>
<div id="intro-wrap">
	<div class="top-banner-wrap">
		<div>
			<div class="main-title">세계 트랜드를 선도하는 평생학습이 시작!</div>
			<div class="sub-title">글로벌 인재 양성</div>
            <div class="photo-watermark">Photo: Tom William Chapman, Runway Beauty</div>
        </div>
	</div>
	<div class="section-cont">
		<h1>교육원위치</h1>
		<div style="width:1024px;margin:0 auto;">
			<div style="padding-top:20px;">
				<KakaoMap :lat="coordinate.lat" :lng="coordinate.lng" :draggable="true" :style="{ width: '1000px', height: '500px' }">
					<KakaoMapMarker :lat="coordinate.lat" :lng="coordinate.lng"></KakaoMapMarker>
				</KakaoMap>
			</div>
			<div class="desc" style="padding-top:20px">주소 : 경기도 성남시 분당구 정자일로1 코오롱트리폴리스 B동 1212호 (우)13616</div>
		</div>
	</div>
</div>
</template>

<script setup>
import { useKakao } from 'vue3-kakao-maps/@utils';
import { KakaoMap,KakaoMapMarker } from 'vue3-kakao-maps';
useKakao('eeb0be28b68c5348f7b140fd595d4266');

const coordinate = {
  lat: 37.3507847,
  lng: 127.1053966
};

</script>
