import { createWebHistory, createRouter } from "vue-router";
import MainComponent from "@components/MainComponent.vue";
import GreetingsComponent from "@components/w/GreetingsComponent.vue";
import PurposeComponent from "@components/w/PurposeComponent.vue";
import LocationComponent from "@components/w/LocationComponent.vue";

import Curriculum1Component from "@components/h/Curriculum1Component.vue";
import Curriculum2Component from "@components/h/Curriculum2Component.vue";
import Curriculum3Component from "@components/h/Curriculum3Component.vue";
import Curriculum4Component from "@components/h/Curriculum4Component.vue";


import Lecroom1Component from "@components/p/Lecroom1Component.vue";
import Lecroom2Component from "@components/p/Lecroom2Component.vue";

import FaqComponent from "@components/n/FaqComponent.vue";
import NoticeComponent from "@components/n/NoticeComponent.vue";
import DataComponent from "@components/n/DataComponent.vue";
import InquiryComponent from "@components/n/InquiryComponent.vue";


import SeminarComponent from "@components/e/SeminarComponent.vue";
import CompetitionComponent from "@components/e/CompetitionComponent.vue";

const routes = [
    {
        path: "/",
        name: "main",
        component: MainComponent,
    },
    {
        path: "/w/greetings", 
        name: "greetings", 
        component: GreetingsComponent, 

    },
    {
        path: "/w/purpose", 
        name: "purpose", 
        component: PurposeComponent, 

    },
    {
        path: "/w/location", 
        name: "location",
        component: LocationComponent,

    },
    {
        path: "/h/curriculum1", 
        name: "curriculum1",
        component: Curriculum1Component,
    },
    {
        path: "/h/curriculum2", 
        name: "curriculum2",
        component: Curriculum2Component,
    },    {
        path: "/h/curriculum3", 
        name: "curriculum3",
        component: Curriculum3Component,
    },    {
        path: "/h/curriculum4", 
        name: "curriculum4",
        component: Curriculum4Component,
    },
    {
        path: "/p/lecroom", 
        name: "lecroom",
        component: Lecroom1Component,
    },
    {
        path: "/p/lecroom2", 
        name: "lecroom2",
        component: Lecroom2Component,
    },
    {
        path: "/e/seminar", 
        name: "seminar",
        component: SeminarComponent,
    },
    {
        path: "/e/competition", 
        name: "competition",
        component: CompetitionComponent,
    },
    {
        path: "/n/notice", 
        name: "notice",
        component: NoticeComponent,
    },
    {
        path: "/n/faq", 
        name: "faq",
        component: FaqComponent,
    },
    {
        path: "/n/data", 
        name: "data",
        component: DataComponent,
    },
    {
        path: "/n/inquiry", 
        name: "inquiry",
        component: InquiryComponent,
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "route-active",
    linkExactActiveClass: "route-active"
});

export default router;