<template>
<div id="power-wrap">
	<div class="top-banner-wrap">
		<div>
			<div class="main-title">네일드릴 전문가 과정</div>
			<div class="sub-title">글로벌 인재 양성</div>
            <div class="photo-watermark">Photo: Tom William Chapman, Runway Beauty</div>
        </div>
	</div>
	<div class="section-cont">
		<h1>네일드릴 전문가 과정란?</h1>
		<div class="desc">네일드릴 전문가 과정은 이러이러하다 </div>
		<h1>이수과목</h1>
		<div class="curriculum-wrap bg3">
			<div class="curriculum-sub-wrap">
				<li class="curriculum">
					<div>네일의 구조</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
				<li class="curriculum">
					<div>드릴과 비트 이해</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
			</div>
			<div class="curriculum-sub-wrap">
				<li class="curriculum">
					<div>인조손톱&레드컬러 드릴파일링 (2주소요)</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
				<li class="curriculum">
					<div>인조손톱&원톤스캅춰 드릴파일링 (3주소요)</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
			</div>
			<div class="curriculum-sub-wrap">
				<li class="curriculum">
					<div>젤 네일 오프과정 파일링(2주 소요)</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
				<li class="curriculum">
					<div>온 네일 오프과정 파일링 (3주 소요)</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
			</div>
		</div>
	</div>
</div>
</template>

<script setup>
</script>
<style scoped>
@import "../../assets/scss/power.scss";
</style>
