<template>
<div id="power-wrap">
	<div class="top-banner-wrap">
		<div>
			<div class="main-title">독서심리치료지도사</div>
			<div class="sub-title">글로벌 인재 양성</div>
            <div class="photo-watermark">Photo: Tom William Chapman, Runway Beauty</div>
        </div>
	</div>
	<div class="section-cont">
		<h1>독서심리치료지도사란?</h1>
		<div class="desc">독서심리치료는 이러이러하다 </div>
		<h1>이수과목</h1>
		<div class="curriculum-wrap bg1">
			<div class="curriculum-sub-wrap">
				<li class="curriculum">
					<div>청소년 심리학과 독서치교 상담기법</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
				<li class="curriculum">
					<div>대인기피증과 사회공포증 & 독서치료</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
			</div>
			<div class="curriculum-sub-wrap">
				<li class="curriculum">
					<div>부부치료와 가족치료를 위한 독서치료</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
				<li class="curriculum">
					<div> 독서치료 속 미술치료</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
			</div>
			<div class="curriculum-sub-wrap">
				<li class="curriculum">
					<div>생리심리학과 독서치료</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
				<li class="curriculum">
					<div>긍정심리학과 행복심리 <br> 긍정과 행복에 관련된 논문</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
			</div>
			<div class="curriculum-sub-wrap">
				<li class="curriculum">
					<div>독서가 무의식을 치료한다?</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
				<li class="curriculum">
					<div>정상과 비정상(이상심리)의 역사 <br> (이상심리학)</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
			</div>
			<div class="curriculum-sub-wrap">
				<li class="curriculum">
					<div>독서와 성격의 관련성</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
				<li class="curriculum">
					<div>겉으로 드러난 성격과 죄책감 없이 반복되는 문제</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
			</div>
			<div class="curriculum-sub-wrap">
				<li class="curriculum">
					<div>독서와 자존감의 관계 (뇌과학적 근거)</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
				<li class="curriculum">
					<div>마음속 방어기제와 승화 (방어기제 전체)</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
			</div>
		</div>
	</div>
</div>
</template>

<script setup>
</script>
<style scoped>
@import "../../assets/scss/power.scss";
</style>
