<template>
<div id="intro-wrap">
	<div class="top-banner-wrap">
		<div>
			<div class="main-title">세계 트랜드를 선도하는 평생학습이 시작!</div>
			<div class="sub-title">글로벌 인재 양성</div>
            <div class="photo-watermark">Photo: Tom William Chapman, Runway Beauty</div>
        </div>
	</div>
	<div class="section-cont">
		<h1>인사말</h1>
		<div class="desc">INTO원격평생교육원은 모든사람이 평생교육을 통하여 학습에 참여할 수 있도록 사회교육의 기회를 제공합니다. </div>
		
	</div>
</div>
</template>

<script setup>
</script>
<style scoped>
@import "../../assets/scss/intro.scss";
</style>
