<template>
<div id="power-wrap">
	<div class="top-banner-wrap">
		<div>
			<div class="main-title">미술심리치료지도사</div>
			<div class="sub-title">글로벌 인재 양성</div>
            <div class="photo-watermark">Photo: Tom William Chapman, Runway Beauty</div>
        </div>
	</div>
	<div class="section-cont">
		<h1>미술심리치료지도사란?</h1>
		<div class="desc">미술심리치료지도사는 이러이러하다 </div>
		<h1>이수과목</h1>
		<div class="curriculum-wrap bg2">
			<div class="curriculum-sub-wrap">
				<li class="curriculum">
					<div>미술치료의 이해</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
				<li class="curriculum">
					<div>미술치료 환경과 매체의 이해</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
			</div>
			<div class="curriculum-sub-wrap">
				<li class="curriculum">
					<div>미술치료와 상징</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
				<li class="curriculum">
					<div>색채심리의 이해</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
			</div>
			<div class="curriculum-sub-wrap">
				<li class="curriculum">
					<div>그림 진단기법의 이해</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
				<li class="curriculum">
					<div>HTP의 이해</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
			</div>
			<div class="curriculum-sub-wrap">
				<li class="curriculum">
					<div>K-HTP, KFD, KSD의 이해</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
				<li class="curriculum">
					<div>가족체계 진단법</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
			</div>
			<div class="curriculum-sub-wrap">
				<li class="curriculum">
					<div>동그라미 중심가족화, 동물 가족화</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
				<li class="curriculum">
					<div>미술치료의 과정</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
			</div>
			<div class="curriculum-sub-wrap">
				<li class="curriculum">
					<div>집단. 아동미술치료의 이해</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
				<li class="curriculum">
					<div>성인.노인미술치료의 이해</div>
					<div class="sub-txt">- 이러한 교육</div>
				</li>
			</div>
		</div>
	</div>
</div>
</template>

<script setup>
</script>
<style scoped>
@import "../../assets/scss/power.scss";
</style>
